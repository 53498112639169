import styled from '@emotion/styled';
import {
	CancelIcon,
	Responsive,
	SearchInputButtonWrap,
	StyledIconButton,
	TextField,
} from '@m2/blueprint-ui';
import { DialogOverlay } from '@reach/dialog';
import { observer } from 'mobx-react';
import React, { useEffect, useState, useRef } from 'react';
import { SearchOverlayViewModel } from './SearchOverlayViewModel';
import { SearchResultItem } from './SearchResultItem';
import { UnifiedSearchEmptyState } from './UnifiedSearchEmptyState';
import { UnifiedSearchNothingFound } from './UnifiedSearchNothingFound';
import { Color } from '../../styles/Color';
import { FilterButton } from '../../components/common/button/FilterButton';
import useDoesContentFitWindow from '../hooks/useDoesContentFitWindow';
import { useTranslation } from '../../i18n/translate/useTranslation';
import { UnifiedSearchItemShimmer } from './UnifiedSearchItemShimmer';

interface ISearchOverlayProps {
	viewModel: SearchOverlayViewModel;
}

const LIST_ITEMS_BOTTOM_PADDING = 120;

export const SearchOverlay = observer(({ viewModel }: ISearchOverlayProps) => {
	const { t } = useTranslation();
	const searchItemsRef = useRef<HTMLDivElement>(null);
	const itemsFitWindow = useDoesContentFitWindow(
		viewModel,
		searchItemsRef,
		LIST_ITEMS_BOTTOM_PADDING,
	);

	useEffect(() => {
		viewModel.onMount();

		return () => viewModel.onUnmount();
	}, []);

	const handleRemoveRecentSearch = (key: string) => {
		viewModel.removeRecentSearch(key);
	};

	const showEndOfResults = !!(
		!viewModel.showRecentSearches && viewModel.resultItemViewModels?.length
	);

	const handlePopularSearchClick = (value: string) => {
		viewModel.changeUserQuery(value);
		viewModel.onSearch(value);
	};

	const [isDebouncing, setIsDebouncing] = useState(false);
	const debounceTimerRef = useRef<number | null>(null);

	const handleSearchInputChange = (value: string) => {
		viewModel.changeUserQuery(value);

		if (debounceTimerRef.current) {
			clearTimeout(debounceTimerRef.current);
		}

		debounceTimerRef.current = window.setTimeout(() => {
			viewModel.onSearch(value);
			setIsDebouncing(false);
		}, 1000);

		setIsDebouncing(true);
	};

	return (
		<StyledDialogOverlay isOpen={viewModel.isUnifiedSearchActive}>
			<StyledOverlayWrapper>
				<StyledContent>
					<StyledSearchInputButtonWrap
						onClickSearch={() => {
							viewModel.onSearch(viewModel.userQuery);
						}}
					>
						<StyledTextField
							autoFocus
							onChange={(ev) => handleSearchInputChange(ev.currentTarget.value)}
							value={viewModel.userQuery ?? ''}
							placeholder={viewModel.placeholderText}
							name="unified-search"
							ariaLabel={viewModel.placeholderText}
						/>
						{viewModel.hasUserQuery ? (
							<StyledClearInput onClick={viewModel.handleClearInput}>
								<StyledCancelIcon color="red" />
							</StyledClearInput>
						) : null}
					</StyledSearchInputButtonWrap>

					<>
						<StyledTopWrapper showFilters={viewModel.showFilters}>
							{viewModel.showFilters && (
								<StyledFiltersWrapper>
									{viewModel.filterButtons.map((filter) => (
										<StyledFilterButton
											key={filter.type}
											isActive={filter.isActive}
											hasIcon={filter.isActive}
											onClick={() => viewModel.onSelectFilter(filter.type)}
										>
											{filter.label}
											{filter.isActive && <CancelIcon variation="inverted" />}
										</StyledFilterButton>
									))}
								</StyledFiltersWrapper>
							)}

							<StyledSearchTitle>
								{!isDebouncing &&
								viewModel.showRecentSearches &&
								viewModel.recentSearchViewModels.length > 0
									? viewModel.recentSearchesText
									: viewModel.resultItemViewModels.length > 0 && !isDebouncing
									? viewModel.searchResultsCountText
									: null}
							</StyledSearchTitle>
						</StyledTopWrapper>

						<StyledListItems ref={searchItemsRef}>
							{!isDebouncing &&
								viewModel.showRecentSearches &&
								viewModel.recentSearchViewModels?.map((recentViewModel) => (
									<SearchResultItem
										isRecentSearchesMode
										key={`recent-${recentViewModel.item.key}`}
										viewModel={recentViewModel}
										onRemove={() =>
											handleRemoveRecentSearch(recentViewModel.item.key)
										}
									/>
								))}

							{!isDebouncing ? (
								!viewModel.showRecentSearches &&
								viewModel.resultItemViewModels?.map((resultViewModel) => (
									<SearchResultItem
										key={`result-${resultViewModel.item.key}`}
										viewModel={resultViewModel}
									/>
								))
							) : (
								<>
									<StyledSearchLoadingTitle>
										{t('unified_search_loading_title')}
									</StyledSearchLoadingTitle>
									<UnifiedSearchItemShimmer />
									<UnifiedSearchItemShimmer />
									<UnifiedSearchItemShimmer />
								</>
							)}

							{showEndOfResults && !itemsFitWindow && (
								<StyledEndOfResults>
									{viewModel.endOfResultText}
								</StyledEndOfResults>
							)}

							{viewModel.nothingFoundViewModel ? (
								<UnifiedSearchNothingFound
									viewModel={viewModel.nothingFoundViewModel}
								/>
							) : null}
						</StyledListItems>
						{viewModel.emptyStateViewModel && (
							<UnifiedSearchEmptyState
								onClick={handlePopularSearchClick}
								viewModel={viewModel.emptyStateViewModel}
							/>
						)}
					</>
				</StyledContent>
			</StyledOverlayWrapper>
		</StyledDialogOverlay>
	);
});

const StyledDialogOverlay = styled(DialogOverlay)`
	&[data-reach-dialog-overlay] {
		z-index: 2;
	}
`;

const StyledContent = styled.div`
	width: 80%;
	display: flex;
	flex-direction: column;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		width: calc(100% - 32px);
	}
`;

const StyledSearchTitle = styled.p`
	font-weight: bold;
	font-size: 16px;
	margin-bottom: 4px;
`;

const StyledSearchLoadingTitle = styled.p`
	font-weight: bold;
	font-size: 16px;
	margin-bottom: 4px;
`;

const StyledListItems = styled.div`
	overflow: auto;
	display: flex;
	flex-direction: column;
	padding-bottom: ${LIST_ITEMS_BOTTOM_PADDING}px;
	gap: 4px;
`;

const StyledOverlayWrapper = styled.div`
	display: flex;
	justify-content: center;
	padding-top: 33px;
	width: 100%;
	max-width: 100vw;
	height: calc(100vh - 80px);
	background-color: ${Color.PrimaryLightGray50};
	position: fixed;
	top: 80px;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		top: 60px;
		height: calc(100vh - 60px);
	}
`;

const StyledTopWrapper = styled.div<{ showFilters: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: ${({ showFilters }) => (showFilters ? '0' : '32px')};
`;

const StyledFiltersWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
	margin: 32px 0;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		margin: 24px 0;
	}
`;

const StyledFilterButton = styled(FilterButton)`
	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		font-size: 15px;
	}
`;

const StyledSearchInputButtonWrap = styled(SearchInputButtonWrap)`
	position: relative;
	box-shadow: none;

	&:focus-within {
		box-shadow: none;
	}

	> ${StyledIconButton} {
		border-top-left-radius: 0px !important;
		border-bottom-left-radius: 0px !important;
	}
`;

const StyledTextField = styled(TextField)`
	border-top-right-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
`;

const StyledClearInput = styled.div`
	position: absolute;
	right: 70px;
	top: 12px;
	cursor: pointer;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		top: 8px;
	}
`;

const StyledEndOfResults = styled.div`
	width: 100%;
	text-align: center;
	margin: 20px 0;
`;

const StyledCancelIcon = styled(CancelIcon)`
	g {
		stroke: #585858;
	}
`;
